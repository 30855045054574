<script lang="ts">
	import '../app.css';
	import '../../node_modules/prism-themes/themes/prism-vsc-dark-plus.css';

	import Header from '$lib/components/header.svelte';
	import Main from '$lib/components/main.svelte';
	import Footer from '$lib/components/footer.svelte';
	import { appName, appKeywords, appDescription, appAuthor } from '$lib/meta';
	import image from '$lib/assets/android-chrome-512x512.png';
	import { onMount } from 'svelte';
	import { onNavigate } from '$app/navigation';

	onNavigate((navigation) => {
		if (!document.startViewTransition) return;

		return new Promise((resolve) => {
			document.startViewTransition(async () => {
				resolve();
				await navigation.complete;
			});
		});
	});

	onMount(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    });
</script>

<svelte:head>
	<title>{appName}</title>

	<meta name="description" content={appDescription} />
	<meta name="keywords" content={appKeywords.join(', ')} />

	<meta name="author" content={appAuthor} />

	<meta property="og:site_name" content="blog.siposdani87" />
	<meta property="og:title" content="blog.siposdani87" />
	<meta property="og:description" content="Dániel Sipos blog site" />
	<meta property="og:url" content="https://blog.siposdani87.com" />
	<meta property="og:type" content="website" />
	<meta property="og:locale" content="en-GB" />
	<meta property="og:image" content={image} />
	<meta
		property="og:image:secure_url"
		content="https://siposdani87.com/assets/images/favicons/android-chrome-512x512.png"
	/>
	<meta property="og:image:type" content="image/jpeg" />
	<meta property="og:image:width" content="720" />
	<meta property="og:image:height" content="420" />
	<meta property="og:image:alt" content="siposdani87 logo" />

	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:site" content="@siposdani87" />

	<link rel="alternate" type="application/rss+xml" title="RSS Feed for BLOG.siposdani87" href="rss.xml" />
</svelte:head>

<Header />
<Main>
	<slot />
	<!-- blog.siposdani87 -->
	<ins class="adsbygoogle"
		style="display:block"
		data-ad-client="ca-pub-9025727238520621"
		data-ad-slot="6677427074"
		data-ad-format="auto"
		data-full-width-responsive="true"></ins>
</Main>
<Footer />
