<script lang="ts">
	import { page } from '$app/stores';
	import logo from '$lib/assets/siposdani87-logo.svg';
	import { siteBaseUrl } from '$lib/meta';
</script>

<header
	class="bg-white/80 text-black dark:bg-black/80 dark:text-white px-3 py-2 flex justify-between items-center sticky top-0 drop-shadow z-50 backdrop-blur-sm"
>
	<a href="/" class="flex items-center">
		<img src={logo} alt="siposdani87" class="logo w-10" />
		<strong class="inline-block ml-2 truncate">BLOG.siposdani87</strong>
	</a>

	<nav>
		<ul class="flex">
			<li class:active={$page.url.pathname === '/'}>
				<a href="/">Home</a>
			</li>
			<li class:active={$page.url.pathname === '/posts'}>
				<a href="/posts">Posts</a>
			</li>
			<li>
				<a href={siteBaseUrl} target="_blank" rel="noreferrer">About</a>
			</li>
		</ul>
	</nav>
</header>

<style lang="postcss">
	header {
		view-transition-name: header;
	}
	@media (prefers-color-scheme: dark) {
		.logo {
			filter: invert(100%) sepia(19%) saturate(2%) hue-rotate(23deg) brightness(102%) contrast(101%);
		}
	}
	li {
		@apply px-3;
	}

	.active {
		@apply font-bold underline decoration-primary;
	}

	a:hover {
		@apply underline decoration-primary;
	}
</style>
