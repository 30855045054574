<script lang="ts">
	import { siteBaseUrl, appName, appAuthor } from '$lib/meta';
	import logo from '$lib/assets/siposdani87-logo.svg';

	let version: string = __APP_VERSION__;
	let startYear = 2021;
	let currentYear: number = new Date().getFullYear();
</script>

<footer class="container mx-auto shadow-xl rounded-t-lg bg-white text-black dark:bg-black dark:text-white">
    <div class="md:flex md:gap-4 py-4 px-2 md:px-5">
        <div class="md:flex-1">
            <a href="/">
                <img
                        class="inline-block logo dark:invert"
                        src={logo}
                        alt={appName}
                        width="192"
                        height="192"
                />
            </a>
            <strong class="pl-2">{appName}</strong>
            <p class="mt-3 text-slate-500">An application is living, while it is being developed.</p>
        </div>
        <div class="md:flex-1">
            <strong>SaaS</strong>
            <div class="flex flex-col">
                <a class="link" href="https://trophymap.org" target="_blank" rel="noopener noreferrer">TrophyMap</a>
                <a class="link" href="https://i18nature.com" target="_blank" rel="noopener noreferrer">I18Nature</a>
                <a class="link" href="https://rejtvenyepito.hu" target="_blank" rel="noopener noreferrer">Puzzle Builder</a>
            </div>
        </div>
        <div class="md:flex-1">
            <strong>Community</strong>
            <div class="flex flex-col">
                <a class="link" href="https://blog.siposdani87.com" target="_blank" rel="noopener noreferrer">Blog</a>
                <a class="link" href="https://github.com/siposdani87" target="_blank" rel="noopener noreferrer">GitHub</a>
                <a class="link" href="https://discord.gg/g6DhrqGfuN" target="_blank" rel="noopener noreferrer">Discord</a>
            </div>
        </div>
    </div>
	<div class="px-3">
		<div class="border-t border-neutral-200 dark:border-neutral-800"></div>
	</div>
    <div class="py-4">
        <div class="mb-3 text-center">
                <span class="mr-1">© {startYear} - {currentYear}</span
                ><a href={siteBaseUrl}>{appAuthor}</a>,
            <span>All rights reserved.</span> <em class="ml-1">v{version}</em>
        </div>
        <div class="text-center">
            Powered by <a href="https://svelte.dev/" target="_blank" rel="noreferrer">Svelte</a> &
			<a href="https://vitejs.dev" target="_blank" rel="noreferrer">Vite</a>
        </div>
    </div>
</footer>



<style lang="postcss">
    footer {
		view-transition-name: footer;
	}
	a {
		@apply text-primary;
	}
	a:hover {
		@apply underline;
	}

	.logo {
        max-width: 45px;
        border-radius: 5px;
    }

    .link {
        padding: 0.25em 0;
        text-decoration: none;
    }
</style>
